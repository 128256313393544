/**
 * Enum containing different error states for the number input component.
 */
export var NumberErrorType;
(function (NumberErrorType) {
    NumberErrorType["NO_ERROR"] = "NO_ERROR";
    NumberErrorType["UNDER_MIN"] = "UNDER_MIN";
    NumberErrorType["OVER_MAX"] = "OVER_MAX";
    NumberErrorType["NOT_IN_RANGE"] = "NOT_IN_RANGE";
})(NumberErrorType || (NumberErrorType = {}));
